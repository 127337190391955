import {Box, Center, Image, VStack} from "@chakra-ui/react";
import {SocialMediaLinks} from "./components/SocialMediaLinks";

function App() {
    return (
        <Box className="App" w='100vw' h='100vh' bg='black'>
            <Center w='100%' h='100%'>
                <VStack>
                    <Image src='/images/miculeney-logo.png' w={{base:'80vw', md:'50vw'}}/>
                    <SocialMediaLinks/>
                </VStack>
            </Center>
        </Box>
    );
}

export default App;
