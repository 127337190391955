import {HStack, ButtonGroup, IconButton} from '@chakra-ui/react'
import * as React from 'react'
import {FaLinkedin, FaTwitter, FaInstagram, FaEnvelope} from 'react-icons/fa'

export const SocialMediaLinks = (props) => (
    <ButtonGroup variant="ghost" {...props}>
        <HStack spacing={{base:4, md:8}}>
            <IconButton
                as="a"
                href="mailto:mikkom@miculeney.com"
                aria-label="Email"
                color="whiteAlpha.600"
                _hover={{color: "whiteAlpha.800"}}
                _active={{}}
                icon={<FaEnvelope/>}
            />
            <IconButton
                as="a"
                href="https://www.linkedin.com/in/miculeneymikko/"
                aria-label="LinkedIn"
                color="whiteAlpha.600"
                _hover={{color: "whiteAlpha.800"}}
                _active={{}}
                icon={<FaLinkedin />}
            />
            <IconButton
                as="a"
                href="https://instagram.com/miculeney"
                aria-label="Instagram"
                color="whiteAlpha.600"
                _hover={{color: "whiteAlpha.800"}}
                _active={{}}
                icon={<FaInstagram />}
            />
            <IconButton
                as="a"
                href="https://twitter.com/miculeneymikko"
                aria-label="Twitter"
                color="whiteAlpha.600"
                _hover={{color: "whiteAlpha.800"}}
                _active={{}}
                icon={<FaTwitter />}
            />
        </HStack>
    </ButtonGroup>
)

